/* You can add global styles to this file, and also import other style files */

$bootstrap-sass-asset-helper: false;
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$icon-font-name: "glyphicons-halflings-regular";
$cr-path-to-icons-sprite: "https://s3.amazonaws.com/crossroads-assets/images/";
$btn-primary-bg: #52676D;
$btn-primary-border: #52676D;
@import '~crds-styles/assets/stylesheets/crds-styles';
#toast-container {
  z-index: 10;
  .toast {
    width: 100%;
  }
}

body {
  background: $cr-gray-lighter;
}

.app-wrapper {
  background: $cr-white;
}

.jumbotron {
  height: auto;
  margin-bottom: 0;
  p {
    font-size: $font-size-base;
    font-weight: 300;
    opacity: 0.8;
    @media screen and (min-width: $screen-sm) {
      font-size: $font-size-large;
    }
  }
  img {
    max-width: 240px;
    padding-bottom: 15px;
    @media screen and (min-width: $screen-sm) {
      max-width: 100%;
    }
  }
  &.jumbotron-xl {
    padding-top: 40px;
  }
}

#HomepageJumbotronText {
  color: #535353;
  p {
    font-weight: 400;
  }
  img {
    max-height: 360px;
  }
}

#wfHeaderBrand {
  img {
    max-height: 67px;
  }
}

.header {
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
  padding: 0;
  p {
    margin: 0;
  }
}

.footer {
  background: url('https://s3.amazonaws.com/crds-cms-uploads/img/graphics/footer-bkgrd-wayfinder.jpg');
  background-size: cover;
  color: #A8A8A8;
  padding-top: 65px;
  padding-bottom: 60px;
  text-align: center;
  &.push-top {
    margin-top: 120px;
  }
}

.footer-logo {
  margin-bottom: 23px;
  img {
    height: 41px;
  }
}

.footer-logo,
.footer-about {
  @media screen and (min-width: $screen-sm) {
    text-align: left;
  }
}

.footer-about {
  font-size: $font-size-small;
  line-height: 1.43;
}

.footer-social-icons {
  a {
    background-color: transparent;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 50%;
    box-sizing: border-box;
    display: inline-flex;
    height: 36px;
    justify-content: center;
    margin-left: 10px;
    padding: 3px;
    width: 36px;
    align-content: center;
  }
  li {
    color: $cr-gray-lighter;
  }
  @media screen and (min-width: $screen-sm) {
    text-align: right;
  }
}

.sgwayfinder-chart {
  width: 100%;
}

.hide-mobile {
  @media screen and (max-width: $screen-xs) {
    display: none;
  }
}

.hide-desktop {
  @media screen and (min-width: $screen-xs) {
    display: none;
  }
}

.question {
  font-size: 24px;
  margin-bottom: 1.4rem;
  padding-top: 1px;
  p {
    margin-bottom: 1.4rem;
  }
  @media screen and (min-width: $screen-sm) {
    padding-top: 1px;
  }
  .ng-inserted-tooltip {
    border-bottom: 1px dotted $cr-gray-dark;
    color: $cr-cyan;
  }
}

.intro-question,
.welcome-text {
  font-size: 24px;
}

.btn-block {
  text-align: center;
}


.progress {
  margin-bottom: 5px;
}

blockquote {
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 30px;
  >span {
    font-family: $font-family-base;
    font-size: $font-size-base;
    color: $cr-gray-dark;
    margin-top: 0;
  }
}

.results {
  app-fire-content-block+h3 {
    margin-top: 40px;
  }
  h2.section-header {
    margin-top: 70px;
  }
}

.extra-top {
  padding-top: 60px;
}

.encouragement-text p {
  padding: 5px 15px;
  text-align: center;
}

  @media screen and (min-width: 767px) {
    .mobile-flush {
      margin: 6px;
    }
  }
